import React from 'react';
import { textContent } from '../helpers/data';

const TextContent = () => {
    return (
        <div className='md:mt-16 mt-6'>
            <h2 className='text-custom-1 dark:text-white xl:text-[64px] md:text-5xl text-[32px] font-bold xl:leading-[72px] lg:leading-[56px] leading-10 md:text-left text-center m-0'>
                { textContent.title }
            </h2>
            <p className='text-custom-2 dark:text-custom-6 xl:text-2xl md:text-lg text-base font-normal md:leading-8 leading-[24.36px] m-0 md:mt-4 mt-2 md:text-left text-center'>
                { textContent.text }
            </p>
        </div>
    )
}

export default TextContent
