import React, { useState, useEffect } from 'react';
import { StytchProvider } from '@stytch/react';
import { StytchHeadlessClient } from '@stytch/vanilla-js/headless';
import NewsLetterSubscription from './NewsLetterSubscription';

const GameNewsLetter = () => {
    const [stytchInit, setStytchInit] = useState('');

    useEffect(() => {
        const stytchClient = new StytchHeadlessClient(
            process.env.REACT_APP_STYTCH_PUBLIC_TOKEN,
        );
        setStytchInit(stytchClient);
    }, []);

    return (
        stytchInit && (
            <StytchProvider stytch={stytchInit}>
                <NewsLetterSubscription />
            </StytchProvider>
        )
    )
}

export default GameNewsLetter
