import { configureStore } from '@reduxjs/toolkit';
import countryCodeReducer from './countryCodeSlice';
import gamesReducer from './gamesSlice';
import earnAllianceReducer from './earnAllianceSlice';

const store = configureStore({
	reducer: {
		countryCode: countryCodeReducer,
		games: gamesReducer,
		earnAllianceGames: earnAllianceReducer,
	},
});

export default store;
