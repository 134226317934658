import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import xml2js from 'xml2js';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';

const getDate = (date) => {
    const givenDate = new Date(date);
    const now = new Date();
    const timeDifference = now - givenDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (days > 0) {
        return `${days} ${days > 1 ? 'days' : 'day'} ago`;
    }

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    if (hours > 0) {
        return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
    }

    const minutes = Math.floor(timeDifference / (1000 * 60));
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
};

const NewsSection = (props) => {

    const {
        title,
        url,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [newsItems, setNewsItems] = useState([]);
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('https://news.bitcoin.com/feed', {
                    headers: {'Accept': 'application/xml'}
                });

                const xml = response.data;
                xml2js.parseString(xml, { explicitArray: false }, (err, result) => {
                    if (err) {
                      throw err;
                    }
                    
                    const items = result.rss.channel.item.map(item => ({
                        ...item,
                        timeAgo: getDate(item.pubDate)
                    }));

                    setNewsItems(items);
                });
            } catch (error) {
                console.error("There was an error fetching the XML data: ", error);
            }
        };

        fetchNews();
        
    }, []);
  
    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 600;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = useCallback(() => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, [checkArrowsVisibility]);

    useEffect(() => {
        checkArrowsVisibility();
    }, [newsItems, checkArrowsVisibility]);

    return (
        <div id='newsSection' className='xl:mt-[100px] lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-1 dark:text-white md:text-[40px] text-2xl font-bold md:leading-[44px] leading-8 m-0'>{ title }</h2>
                <a
                    href={ url }
                    target='_blank'
                    rel='noreferrer'
                    className='bg-custom-blue hover:bg-custom-10 dark:bg-dark dark:hover:bg-custom-11 text-primary text-base font-bold leading-[17.6px] rounded-xl md:px-8 md:py-3 px-4 py-1 cursor-pointer'
                >
                    View all
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    { newsItems.map((news, index) => (
                        <a 
                            key={`news-${index}`}
                            href={ news.link }
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='flex flex-col bg-white dark:bg-custom-7 flex-item rounded-xl xl:w-[313px] md:w-[288px] w-[307.5px] h-full'>
                                <img
                                    className='w-full rounded-t-xl'
                                    src={ news['bnmedia:post-thumbnail']['bnmedia:url'] }
                                    alt="news thumbnail"
                                    width={ isMobile ? 307.5 : isTablet ? 288 : 313 }
                                    height={ isMobile ? 173 : isTablet ? 162 : 180 }
                                />
                                <div className='flex flex-col justify-between h-full gap-6 p-6'>
                                    <h3 className='text-custom-1 dark:text-white md:text-2xl text-lg md:font-semibold font-bold md:leading-[28.64px] leading-[26px] m-0'>{ news.title }</h3>
                                    <p className='text-custom-3 text-lg font-bold leading-[26px] m-0'>{ news.timeAgo }</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default NewsSection
