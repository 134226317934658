import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';

const Web3Games = (props) => {

    const {
        title,
        GameList,
        sectionId,
        url,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    return (
        <div id={ sectionId } className='xl:mt-[100px] lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-1 dark:text-white md:text-[40px] text-2xl font-bold md:leading-[44px] leading-8 m-0'>{ title }</h2>
                <a
                    href={ url }
                    target='_blank'
                    rel='noreferrer'
                    className='bg-custom-blue hover:bg-custom-10 dark:bg-dark dark:hover:bg-custom-11 text-primary text-base font-bold leading-[17.6px] rounded-xl md:px-8 md:py-3 px-4 py-1 cursor-pointer'
                >
                    View all
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    { GameList.map((game, gameIndex) => (
                        <a
                            key={`game-${gameIndex}`}
                            href={ game.url }
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='flex flex-col bg-white dark:bg-custom-7 flex-item rounded-xl xl:w-[311px] md:w-[288px] w-[307.5px] h-full'>
                                <img
                                    src={ game.image }
                                    alt={ game.altText }
                                    className='rounded-t-xl'
                                    width={ isMobile ? 307.5 : isTablet ? 288 : 311 }
                                    height={ isMobile ? 244.5 : isTablet ? 229 : 247 }    
                                />
                                <div className='flex flex-col gap-2 p-6'>
                                    <span className='text-custom-13'>{ game.highlightText }</span>
                                    <h3 className='text-custom-1 dark:text-white text-2xl font-semibold md:leading-[28.64px] m-0'>{ game.heading }</h3>
                                    <p className='text-custom-2 dark:text-custom-6 text-base font-normal leading-[27.84px] m-0 mt-4'>{ game.text }</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Web3Games
