import React from 'react';
import { SocialMediaList } from '../helpers/data';

const SocialMedia = (props) => {

    const {
        marginTop,
        theme,
    } = props;

    return (
        <div className={`flex flex-col ${marginTop}`}>
            <h5 className='text-custom-1 dark:text-white text-lg font-bold leading-[26px] m-0 mb-2'>{ SocialMediaList.title }</h5>
            <div className='flex gap-6'>
                { SocialMediaList.socialMedia.map((social, index) => (
                    <a
                        key={`social-${index}`}
                        href={social.url}
                        target='_blank'
                        rel='noreferrer'
                        className='block text-custom-2 text-lg font-normal leading-8'
                    >
                        <img
                            src={ theme === 'light' ? social.icon : social.darkIcon }
                            alt={ social.altText }
                            width={40}
                            height={40}
                        />
                    </a>
                ))}
            </div>
        </div>
    )
}

export default SocialMedia
