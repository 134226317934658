import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCountryCode = createAsyncThunk('data/fetchCountryCode', async () => {
    const response = await axios.get('https://tokyo-neko-web.ops.bitcoin.com/api/v1/location/info', {
        headers: {'Accept': 'application/json'}
    });
    const data = response.data.countryCode;
    return data;
});

const countryCodeSlice = createSlice({
    name: 'countryCode',
    initialState: {
        value: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCountryCode.fulfilled, (state, action) => {
            state.value = action.payload;
        });
    },
});
  
export default countryCodeSlice.reducer;
