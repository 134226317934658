import React from "react";

const BitcoinPlayblockIframe = () => {
  return (
    <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
      <iframe
        src="https://bitcoin.playblock.io/"
        style={{
          width: "100%",
          height: "100vh",
          border: "none",
          overflow: "hidden",
        }}
        scrolling="no"
        title="Bitcoin Playblock"
      />
    </div>
  );
};

export default BitcoinPlayblockIframe;
