import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useSelector } from "react-redux";
import '../styles/carousel.css';

const EarnAllianceGames = (props) => {

    const {
        title,
        sectionId,
        url,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const gameList = useSelector((state) => state.earnAllianceGames.games);
    const gamesUrl = process.env.REACT_APP_GAMES_URL;
    const cdnUrl = process.env.REACT_APP_CDN_URL;

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;
            
            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = useCallback(() => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    }, [carouselRef]);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, [checkArrowsVisibility]);

    useEffect(() => {
        checkArrowsVisibility();
    }, [gameList, checkArrowsVisibility]);

    const getThumbnailImagePath = (assets) => {
        const thumbnail = assets.find(asset => 
            asset.assetKey === "THUMBNAIL" && asset.assetType === "IMAGE_PATH"
        );
        return thumbnail ? thumbnail.assetValue : null;
    };

    return (
        <div id={ sectionId } className='xl:mt-[100px] lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-1 dark:text-white md:text-[40px] text-2xl font-bold md:leading-[44px] leading-8 m-0'>{ title }</h2>
                    <a
                        href={ url }
                        target='_blank'
                        rel='noreferrer'
                        className='bg-custom-blue hover:bg-custom-10 dark:bg-dark dark:hover:bg-custom-11 text-primary text-base font-bold leading-[17.6px] rounded-xl md:px-8 md:py-3 px-4 py-1 cursor-pointer'
                    >
                        View all
                    </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    { gameList.map((game, index) => {
                        const gameLink = `${gamesUrl}${game.game.slug}?utm_source=bitcoincom`;
                        const imagePath = getThumbnailImagePath(game.game.assets);
                        const imageUrl = `${cdnUrl}${imagePath}`;
                        
                        return (
                            <a
                                key={`game-${index}`}
                                href={gameLink}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <div className='flex flex-col bg-white dark:bg-custom-7 flex-item rounded-xl h-full'>
                                    <img src={ imageUrl } alt={ `${game.game.slug} ${index}` } className='rounded-t-xl' height={ isMobile ? '165.23px' : isTablet ? '154.77px' : '230px' } width={ isMobile ? '307.5px' : isTablet ? '288px' : '428px' } />
                                    <div className='flex flex-col gap-1.5 p-6'>
                                        <h3 className='text-custom-1 dark:text-white md:text-2xl text-lg md:font-semibold font-bold md:leading-[28.64px] leading-[26px] capitalize m-0'>{ game.game.name }</h3>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default EarnAllianceGames
