import React from 'react';
import { ReviewBannerList } from '../helpers/data';

const ReviewBanner = () => {
    return (
        <div className='flex lg:flex-row flex-col-reverse justify-start items-center bg-white dark:bg-custom-7 rounded-xl lg:gap-[60px] gap-12 xl:py-10 xl:px-[60px] md:py-8 md:px-10 py-10 px-6 xl:mt-[100px] lg:mt-20 mt-16'>
            <div className='flex flex-col justify-start items-start md:gap-6 gap-4 lg:w-1/2 w-full'>
                <h3 className='text-custom-1 dark:text-white lg:text-[64px] md:text-5xl text-2xl font-bold md:leading-[72px] leading-8 m-0'>{ ReviewBannerList.title }</h3>
                <p className='text-custom-16 dark:text-custom-6 lg:text-2xl md:text-xl text-base font-normal md:leading-8 leading-7 m-0'>{ ReviewBannerList.text }</p>
                <div
                    className='bg-primary text-white text-base font-bold leading-[22px] md:w-fit w-[calc(100%-64px)] text-center rounded-xl py-3 px-8 cursor-pointer'
                    onClick={() => window.open('http://bitcoin.com/directory/gambling', '_blank')}
                >
                    { ReviewBannerList.button }
                </div>
            </div>
            <div className='flex justify-end items-start md:gap-6 gap-4 lg:w-1/2 w-full'>
                <div className='flex flex-col justify-start items-center gap-4 w-full'>
                    { ReviewBannerList.list_1.map((label, index) => (
                        <div
                            key={`label-${index}`}
                            className='flex justify-start items-center bg-custom-18 dark:bg-custom-17 rounded-xl gap-3 md:w-[calc(100%-48px)] w-[calc(100%-32px)] h-6 py-[17px] md:px-6 px-4 cursor-pointer'
                            onClick={() => window.open(label.link, '_blank')}
                        >
                            <img
                                src={ label.icon }
                                alt={ label.altText }
                                width={ label.width }
                                height={ label.height }
                            />
                            <p className='text-custom-1 dark:text-white md:text-xl text-sm font-bold leading-[22px] m-0'>{ label.text }</p>
                        </div>
                    ))}
                </div>
                <div className='flex flex-col justify-start items-center gap-4 w-full'>
                    { ReviewBannerList.list_2.map((label, index) => (
                        <div
                            key={`label-${index}`}
                            className='flex justify-start items-center bg-custom-18 dark:bg-custom-17 rounded-xl gap-3 md:w-[calc(100%-48px)] w-[calc(100%-32px)] h-6 py-[17px] md:px-6 px-4 cursor-pointer'
                            onClick={() => window.open(label.link, '_blank')}
                        >
                            <img
                                src={ label.icon }
                                alt={ label.altText }
                                width={ label.width }
                                height={ label.height }
                            />
                            <p className='text-custom-1 dark:text-white md:text-xl text-sm font-bold leading-[22px] m-0'>{ label.text }</p>
                        </div>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default ReviewBanner
