import React from 'react'

const TermsAndCondition = () => {
  return (
    <div className='flex xl:mt-[100px] lg:mt-20 mt-16'>
        <p className='text-custom-2 dark:text-custom-6 text-[8px] font-normal leading-[24.36px] m-0'>
            *One lucky winner will be picked to win $1,000 in VERSE. Just enter your email and click "subscribe" to participate in the contest. Campaign runs till 13th October. Only one email per participant is allowed. If you’ve won before, you are not eligible to claim the $1,000 reward again. Other restrictions may apply.
        </p>
    </div>
  )
}

export default TermsAndCondition
