import React from 'react';
import { useMediaQuery } from "react-responsive";

const NewsLetterBanner = () => {

    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    let imageUrl = '/images/news-letter-desktop.png'
    if (isMobile) {
        imageUrl = '/images/news-letter-mobile.png';
    }
    else if (isTablet) {
        imageUrl = '/images/news-letter-tablet.png';
    }

    return (
        <div className='relative flex md:flex-row flex-col-reverse justify-end bg-custom-7 rounded-xl md:h-auto h-[264px] mt-[100px]'>
            <div className='absolute xl:left-14 md:left-9 md:top-12 bottom-8 left-[16%] md:transform-none transform -translate-x-[10%]'>
                <h3 className='text-white xl:text-5xl md:text-[40px] text-xl font-bold md:leading-[56px] leading-[23.87px] m-0'>
                    {
                        isMobile ? (
                            <>
                                Join now for Real Money Cashback on Live Casino & Slots game
                            </>
                        ) : (
                            <>
                                Join now for Real Money Cashback<br/>
                                on Live Casino & Slots game
                            </>
                        )     
                    }
                </h3>
                <div
                    className='bg-white text-custom-1 md:text-xl text-base font-bold md:leading-[22px] leading-[17.6px] md:w-fit text-center rounded-xl md:py-[17px] py-3 md:px-10 px-8 m-0 md:mt-6 mt-4'
                    onClick={() => window.open('https://www.bitcoin.com/newsletters/games-updates/', '_blank')}
                >
                    Opt-in Now
                </div>
            </div>
            <img src={ imageUrl } className='md:h-[280px]' alt="News Letter" />
        </div>
    )
}

export default NewsLetterBanner