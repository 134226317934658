import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { labels } from '../helpers/data';
import '../styles/carousel.css';

const Labels = () => {

    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 500;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const headerHeight = isMobile ? 132 : isTablet ? 174 : 155 ;
        if (element) {
          window.scrollTo({
            top: element.offsetTop - headerHeight,
            behavior: 'smooth',
          });
        }
    };

    return (
        <div className='sticky xl:top-[82px] lg:top-[102px] top-[60px] bg-custom-light dark:bg-custom-dark z-10 lg:mt-10 md:mt-6 mt-4 py-4'>
            <div className="relative submenu cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex overflow-x-auto no-scrollbar md:gap-4 gap-2 cursor-pointer' ref={carouselRef}>
                    { labels.map((label, index) => (
                        <div
                            key={`label-${index}`}
                            onClick={() => scrollToSection(label.link)}
                            className='flex-item'
                        >
                            <div className='flex items-center md:gap-2.5 gap-2 bg-custom-blue hover:bg-custom-10 dark:bg-custom-darkBlue dark:hover:bg-custom-11 h-6 rounded-xl md:px-4 px-3 py-2'>
                                <img
                                    src={ label.icon }
                                    alt={ label.altText }
                                    width={ label.width }
                                    height={ label.height }
                                />
                                <p className='text-primary md:text-base text-sm font-semibold md:leading-[17.6px] leading-[15.4px] m-0'>{ label.text }</p>
                            </div>
                        </div>
                    ))}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow left-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow right-2 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Labels
