export const textContent = {
    title: 'Explore Top Bitcoin & Crypto Games',
    text: 'Navigate the exciting realm of blockchain games and casino',
};

export const labels = [
    {
        icon: '/images/icons/recommended.svg',
        link: 'recommendedGames',
        altText: 'recommended',
        text: 'Recommended',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/popular-new.svg',
        link: 'popularSection',
        altText: 'popular',
        text: 'Popular',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/verse-icon.svg',
        link: 'verseGames',
        altText: 'verse',
        text: 'VERSE Games',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/web3-game.svg',
        link: 'web3Games',
        altText: 'web3 game',
        text: 'Web3 Games',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/slot-machine.svg',
        link: 'slotsGames',
        altText: 'slot',
        text: 'Slots',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/dice.svg',
        link: 'tableGames',
        altText: 'tableGame',
        text: 'Table',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/casino.svg',
        link: 'liveCasinoGames',
        altText: 'liveCasino',
        text: 'Live Casino',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/news.svg',
        link: 'newsSection',
        altText: 'newsIcon',
        text: 'News',
        width: '24',
        height: '24',
    },
];

export const verseGameList = [
    {
        image: '/images/verse-scratch-win.webp',
        altText: 'web game icon',
        highlightText: 'SCRATCH & WIN',
        heading: 'Verse Scratcher',
        text: 'Purchase a ticket and uncover three matching numbers to win VERSE.',
        url: 'https://srt.bitcoin.com/games-scratch',
    },
    {
        image: '/images/verse-clicker.webp',
        altText: 'web game icon',
        highlightText: 'ONE MORE COOKIE',
        heading: 'Verse Clicker',
        text: 'Tap your way to the top with Verse points and ascend the leaderboard! Become part of the Verse community and immerse yourself in non-stop clicking excitement.',
        url: 'https://srt.bitcoin.com/games-clicker',
    },
    {
        image: '/images/verse-voyagers.webp',
        altText: 'web game icon',
        highlightText: 'GET YOUR NFT ON',
        heading: 'Verse Voyagers',
        text: 'Turn the virtual slot machine to create your distinct Voyager. With over 160 million combinations at your disposal, the potential is limitless!',
        url: 'https://srt.bitcoin.com/games-voyager',
    },
    {
        image: '/images/verse-ecosystem.webp',
        altText: 'web game icon',
        highlightText: 'EXPLORE MORE',
        heading: 'Verse Ecosystem',
        text: "VERSE is Bitcoin.com's ecosystem token and the world's gateway to democratized finance",
        url: 'https://srt.bitcoin.com/games-verse',
    },
];

export const Banners = [
    {
        bannerImage: "/images/games-casino-banner.webp",
        heading: "Games Casino",
        backgroundColor: "bg-custom-lightGreen",
        text: "Welcome Offer: Deposit at least $2,000 to get 25% cashback on all slot games you play for the next 14 days!",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-app",
    },
    {
        bannerImage: "/images/verse-voyagers-banner.webp",
        heading: "Verse Voyagers",
        backgroundColor: "bg-primary",
        text: "A collection of 10,000 algorithmically generated unique space farers",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-voyager",
    },
    {
        bannerImage: "/images/video-casino-banner.webp",
        heading: "Bitcoin Video Casino",
        backgroundColor: "bg-custom-15",
        text: "Play Bitcoin Slots. No registration needed, and you can start playing immediately!",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-bvc",
    },
    {
        bannerImage: "/images/verse-scratch-wins-banner.webp",
        heading: "Verse Scratcher",
        backgroundColor: "bg-primary",
        text: "Stand a chance to win the VERSE Jackpot with every scratch ticket",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-scratch",
    },
    {
        bannerImage: "/images/satoshi-dice-banner.webp",
        heading: "Satoshi Dice Dapp",
        backgroundColor: "bg-custom-darkGreen",
        text: "Known for its simplicity, transparency, and instant payouts",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-sd",
    },
    {
        bannerImage: "/images/verse-clicker-banner.webp",
        heading: "Verse Clicker",
        backgroundColor: "bg-primary",
        text: "Join the addictive world of Verse Clicker, where every click earns you Verse Points.",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-clicker",
    },
];

export const PopularGameList = {
    heading: "Brought to you by Stake",
    games: [
        {
            icon: "/images/popular-games/2 Wild 2 Die.jpg",
            altText: "webgame",
            heading: "2 Wild 2 Die",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Play 2 Wild 2 Die, Wild West slot at Stake Casino.',
        },
        {
            icon: "/images/popular-games/Blackjack.jpg",
            altText: "webgame",
            heading: "Blackjack",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Play Blackjack with the lowest house edge online.',
        },
        {
            icon: "/images/popular-games/Cash Crew.jpg",
            altText: "webgame",
            heading: "Cash Crew",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Cash Crew: horror slot, high variance, big wins.',
        },
        {
            icon: "/images/popular-games/Crazy Time.jpg",
            altText: "webgame",
            heading: "Crazy Time",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Crazy Time: thrilling live casino game with bonuses.',
        },
        {
            icon: "/images/popular-games/Dice.jpg",
            altText: "webgame",
            heading: "Dice",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Dice: simple, customizable crypto casino game.',
        },
        {
            icon: "/images/popular-games/Fist Of Destruction.jpg",
            altText: "webgame",
            heading: "Fist Of Destruction",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Street brawl slot, high volatility, big wins online.',
        },
        {
            icon: "/images/popular-games/Miss Rainbow.jpg",
            altText: "webgame",
            heading: "Miss Rainbow",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Crazy Time: thrilling live casino game with bonuses.',
        },
        {
            icon: "/images/popular-games/Plinko.jpg",
            altText: "webgame",
            heading: "Plinko",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Plinko: drop ball, customize risk, win multipliers online.',
        },
        {
            icon: "/images/popular-games/Roulette.jpg",
            altText: "webgame",
            heading: "Roulette",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Super low house edge, high RTP, big win potential.',
        },
        {
            icon: "/images/popular-games/Samurai Dogs.jpg",
            altText: "webgame",
            heading: "Samurai Dogs",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Samurai Dogs, ninja canine slot exclusively at Stake.',
        },
        {
            icon: "/images/popular-games/Underworld.jpg",
            altText: "webgame",
            heading: "Underworld",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Experience horror-themed slot with cascades feature.',
        },
        {
            icon: "/images/popular-games/Wanted Dead or a Wild.jpg",
            altText: "webgame",
            heading: "Wanted Dead or a Wild",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Gritty cowboy slot, high variance, big prizes online.',
        },
        {
            icon: "/images/popular-games/us/Aztec Powernudge.jpg",
            altText: "webgame",
            heading: "Aztec Powernudge",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Venture into the jungle, uncover Aztec treasures.',
        },
        {
            icon: "/images/popular-games/us/Baccarat.jpg",
            altText: "webgame",
            heading: "Baccarat",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Baccarat with exhilarating options at Stake.us.',
        },
        {
            icon: "/images/popular-games/us/Blackjack.jpg",
            altText: "webgame",
            heading: "Blackjack",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Play Blackjack with the lowest house edge online.',
        },
        {
            icon: "/images/popular-games/us/Floating Dragon.jpg",
            altText: "webgame",
            heading: "Floating Dragon",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Explore Oriental riches with Floating Dragon at Stake.',
        },
        {
            icon: "/images/popular-games/us/Multiplier Mine.jpg",
            altText: "webgame",
            heading: "Multiplier Mine",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Join popular game inspired by classic Minesweeper.',
        },
        {
            icon: "/images/popular-games/us/Roulette.jpg",
            altText: "webgame",
            heading: "Roulette",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Super low house edge, high RTP, big win potential.',
        },
        {
            icon: "/images/popular-games/us/Stake Poker.jpg",
            altText: "webgame",
            heading: "Stake Poker",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Enjoy ultimate poker night with friends at Stake.us.',
        },
        {
            icon: "/images/popular-games/us/Sugar Rush.jpg",
            altText: "webgame",
            heading: "Sugar Rush",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Enjoy candy-themed slot with big multipliers at Stake.',
        },
        {
            icon: "/images/popular-games/us/Sweet Bonanza.jpg",
            altText: "webgame",
            heading: "Sweet Bonanza",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Indulge in candy-themed slot with big multipliers.',
        },
        {
            icon: "/images/popular-games/us/Tome of Life.jpg",
            altText: "webgame",
            heading: "Tome of Life",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Explore enchanted forest for wins at Stake social casino.',
        },
        {
            icon: "/images/popular-games/us/Tyrants Fall.jpg",
            altText: "webgame",
            heading: "Tyrants Fall",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Tyrants in this thrilling gameplay experience.',
        },
        {
            icon: "/images/popular-games/us/Wanted Dead or a Wild.jpg",
            altText: "webgame",
            heading: "Wanted Dead or a Wild",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Gritty cowboy slot, high variance, big prizes online.',
        },
    ],
};

export const FooterList = [
    {
        title: "Support",
        links: [
            {
                name: "F.A.Q",
                url: "https://srt.bitcoin.com/games-app-faq",
                externalLink: true,
            },
            {
                name: "VIP",
                url: "https://srt.bitcoin.com/games-app-vip",
                externalLink: true,
            },
            {
                name: "Provably Fair",
                url: "https://srt.bitcoin.com/games-app-pf",
                externalLink: true,
            },
            {
                name: "Advertise",
                url: "mailto:affiliates@bitcoin.com?subject=Games Review Request",
                externalLink: false,
            },
            {
                name: "Affiliate Program",
                url: "https://srt.bitcoin.com/games-aff",
                externalLink: true,
            },
            {
                name: "Terms of Service",
                url: "https://srt.bitcoin.com/games-app-tos",
                externalLink: true,
            },
            {
                name: "Privacy Policy",
                url: "https://srt.bitcoin.com/games-app-priv",
                externalLink: true,
            },
            {
                name: "Buy",
                url: "https://srt.bitcoin.com/games-buy",
                externalLink: true,
            },
            {
                name: "Sell",
                url: "https://srt.bitcoin.com/games-sell",
                externalLink: true,
            },
        ],
    },
    {
        title: "VERSE Games",
        links: [
            {
                name: "Verse Scratcher",
                url: "https://srt.bitcoin.com/games-scratch",
                externalLink: true,
            },
            {
                name: "Verse Clicker",
                url: "https://srt.bitcoin.com/games-clicker",
                externalLink: true,
            },
            {
                name: "Verse Voyagers",
                url: "https://srt.bitcoin.com/games-voyager",
                externalLink: true,
            },
        ],
    },
    {
        title: "Casino",
        links: [
            {
                name: "Slots",
                url: "https://srt.bitcoin.com/games-app",
                externalLink: true,
            },
            {
                name: "Play At Stake",
                url: "https://srt.bitcoin.com/stake-games-footer",
                externalLink: true,
            },
            {
                name: "Gambling",
                url: "https://srt.bitcoin.com/games-gam",
                externalLink: true,
            },
        ],
    },
];

export const SocialMediaList = {
    title: "Social networks",
    socialMedia : [
        // {
        //     icon: "/images/facebook.svg",
        //     darkIcon: "/images/facebook.svg",
        //     altText: "Facebook",
        //     url: "https://srt.bitcoin.com/games-fb",
        // },
        {
            icon: "/images/telegram.svg",
            darkIcon: "/images/telegram.svg",
            altText: "Telegram",
            url: "https://srt.bitcoin.com/games-tg",
        },
        {
            icon: "/images/twitter.svg",
            darkIcon: "/images/twitter-dark.svg",
            altText: "Twitter",
            url: "https://srt.bitcoin.com/games-x",
        },
    ],
};

export const web3GameList = [
    {
        image: '/images/up-or-down.webp',
        altText: 'web game icon',
        title: 'Up or Down',
        url: 'https://games.bitcoin.com/up-or-down',
    },
    {
        image: '/images/axie-infinity.webp',
        altText: 'web game icon',
        title: 'Axie Infinity',
        url: 'https://axieinfinity.com/',
    },
    {
        image: '/images/sunflower-land.webp',
        altText: 'web game icon',
        title: 'Sunflower Land',
        url: 'https://sunflower-land.com/',
    },
    {
        image: '/images/zed-run.webp',
        altText: 'web game icon',
        title: 'Zed Run',
        url: 'https://zed.run/',
    },
    {
        image: '/images/gods-unchained.webp',
        altText: 'web game icon',
        title: 'Gods Unchained',
        url: 'https://godsunchained.com/',
    },
    {
        image: '/images/star-atlas.webp',
        altText: 'web game icon',
        title: 'Star Atlas',
        url: 'https://staratlas.com/',
    },
    {
        image: '/images/ev-io.webp',
        altText: 'web game icon',
        title: 'EV.IO',
        url: 'https://ev.io/',
    },
    {
        image: '/images/rage-effect.webp',
        altText: 'web game icon',
        title: 'Rage Effect',
        url: 'https://www.rageeffect.io/',
    },
    {
        image: '/images/project-seed.webp',
        altText: 'web game icon',
        title: 'Project Seed',
        url: 'https://www.projectseed.com/game',
    },
];

export const ReviewBannerList = {
    title: "Reviews",
    text: "Discover an extensive directory for Bitcoin enthusiasts, offering seamless access to premier gambling services with exclusive welcome offers and detailed, playtested reviews of each platform.",
    button: "Discover now",
    url: "/",
    list_1: [
        {
            icon: '/images/icons/slot-machine.svg',
            link: 'https://www.bitcoin.com/gambling/',
            altText: 'slot',
            text: 'Gambling',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/bitcoin.svg',
            link: 'https://www.bitcoin.com/crypto-casino/',
            altText: 'bitcoin',
            text: 'Crypto Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/star.svg',
            link: 'https://www.bitcoin.com/gambling/casino/vip/',
            altText: 'star',
            text: 'VIP Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/basketball.svg',
            link: 'https://www.bitcoin.com/online-casino/',
            altText: 'liveCasino',
            text: 'Online Casino',
            width: '24',
            height: '24',
        },
    ],
    list_2: [
        {
            icon: '/images/icons/nft.svg',
            link: 'https://www.bitcoin.com/web3-casino/',
            altText: 'nft',
            text: 'Web3 Casino',
            width: '17',
            height: '20',
        },
        {
            icon: '/images/icons/dice.svg',
            link: 'https://www.bitcoin.com/gambling/casino/slots/',
            altText: 'dice',
            text: 'Slots Casino',
            width: '20',
            height: '20',
        },
        {
            icon: '/images/icons/popular.svg',
            link: 'https://www.bitcoin.com/gambling/casino/jackpot/',
            altText: 'popular',
            text: 'Jackpot Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/ethereum.svg',
            link: 'https://www.bitcoin.com/gambling/casino/ethereum/',
            altText: 'ethereum',
            text: 'Ethereum Casino',
            width: '24',
            height: '24',
        },
    ]
}
