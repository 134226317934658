import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_GRAPHQL_API_URL;

export const fetchEarnAllianceGames = createAsyncThunk('games/fetchEarnAllianceGames', async () => {
    const response = await axios.post(API_URL, {
        query: `query MyGames { searchGames(args: {limit: 10, offset: 0}) { count games { game { assets { assetKey assetType assetValue } description id name slug } } } }`
    });
    const data = await response.data.data.searchGames.games;
    return data;
});

const earnAllianceGameSlice = createSlice({
    name: 'earnAllianceGames',
    initialState: {
        games: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEarnAllianceGames.fulfilled, (state, action) => {
            state.games = action.payload;
        });
    },
});

export default earnAllianceGameSlice.reducer;
