import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Banners } from '../helpers/data';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import '../styles/carousel.css';

const Banner = () => {

    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const countryCode = useSelector(state => state.countryCode.value);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    return (
        <div className="relative cursor-pointer"
            onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
            onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
        >
            <div className='flex md:gap-6 gap-4 lg:mt-10 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                { Banners.map((banner, bannerIndex) => (
                    <a
                        key={`banner-${bannerIndex}`}
                        href={ countryCode === 'US' && bannerIndex === 1 ? banner.urlUS : banner.url }
                        target='_blank'
                        rel='noreferrer'
                    >
                        <div className={`${banner.backgroundColor} flex flex-col justify-between flex-item rounded-xl xl:w-[440px] md:w-[432px] w-[307px] h-full`}>
                            <img
                                src={ banner.bannerImage }
                                alt="Banner"
                                className='rounded-t-xl block'
                                width={ isMobile ? 307 : isTablet ? 432 : 440 }
                                height={ isMobile ? 182 : isTablet ? 256 : 261 }
                            />
                            <div className='relative xl:px-10 md:px-8 px-4 xl:pb-10 md:pb-8 pb-4'>
                                <div className='absolute xl:left-10 md:left-8 left-4 xl:right-10 md:right-8 right-4 xl:-top-8 md:-top-6 -top-5'>
                                    <h3 className='banner-heading font-barlow capitalize text-white xl:text-[38.76px] md:text-[32px] text-3xl font-extrabold xl:leading-[46.51px] leading-[38.4px] m-0'>
                                        { banner.heading }
                                    </h3>
                                </div>
                                <p className='text-white text-lg font-bold leading-[26px] m-0 mt-6'>
                                    { banner.text }
                                </p>
                                <div className='bg-white text-custom-1 text-xl font-bold leading-[22px] w-fit text-center rounded-xl md:py-[17px] py-3 md:px-10 px-8 m-0 md:mt-6 mt-4'>
                                    { banner.button }
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            { !isTablet && showLeftArrow && (
                <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                    <SlArrowLeft color='#FFFFFF' />
                </button>
            )}
            { !isTablet && showRightArrow && (
                <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                    <SlArrowRight color='#FFFFFF' />
                </button>
            )}
        </div>
    )
}

export default Banner
