import React from 'react';

const NewsLetter = (props) => {

    const {
        imageUrl,
        url
    } = props;

    return (
        <div className='relative flex md:flex-row flex-col-reverse justify-end bg-custom-5 rounded-xl xl:mt-[100px] lg:mt-20 mt-16'>
            <a
                href={ url }
                target='_blank'
                rel='noreferrer'
                className='w-full'
            >
                <div className='flex'>
                    <img
                        src={ imageUrl }
                        alt="News Letter"
                        className='w-full rounded-xl'
                        height={ '100%' }
                    />
                </div>
            </a>
        </div>
    )
}

export default NewsLetter
