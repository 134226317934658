import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const Header = () => {

    const [isOpen, setIsOpen] = useState(false);
    const handleNavbar = () => setIsOpen(!isOpen);
    const countryCode = useSelector(state => state.countryCode.value);
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');
    let lightSvg = '';
    let darkSvg = '';

    const navItems = [
        { id: 1, text: 'Promotions', link: 'https://srt.bitcoin.com/games-app-prom', externalLink: true },
        { id: 2, text: 'VIP', link: 'https://srt.bitcoin.com/games-app-vip', externalLink: true },
        { id: 3, text: 'Advertise', link: 'mailto:affiliates@bitcoin.com?subject=Games Review Request', externalLink: false },
        { id: 4, text: 'News', link: 'https://srt.bitcoin.com/games-news', externalLink: true },
    ];

    useEffect(() => {
        document.body.className = `${theme} ${theme === 'light' ? 'bg-custom-light' : 'bg-custom-dark'}`;
        localStorage.setItem('theme', theme);

        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [theme, isOpen]);

    const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');

    if (theme === 'light') {
        lightSvg = '/images/light-light.svg';
        darkSvg = '/images/light-dark.svg';
    } else {
        lightSvg = '/images/dark-light.svg';
        darkSvg = '/images/dark-dark.svg';
    }

    return (
        <React.Fragment>
            <div className="flex justify-between items-center sticky top-0 bg-custom-light dark:bg-custom-dark z-[1000] w-full mx-auto py-4 text-black-100">
                <a href="/">
                    <img
                        src={theme === 'light' ? '/images/games-logo.svg' : '/images/games-logo-dark.svg'}
                        alt="Games Logo"
                        width={260}
                        height={24}
                    />
                </a>

                <ul className='hidden lg:flex lg:gap-6'>
                    {navItems.map(item => (
                        <li key={item.id}>
                            <a
                                href={(countryCode === 'US' && item.id === 4) ? item.usLink : item.link}
                                {...(item.externalLink ? { target: '_blank', rel: 'noreferrer' } : {})}
                                className='text-custom-1 dark:text-white hover:text-custom-3 text-base font-bold leading-[17.6px] cursor-pointer'
                            >
                                {item.text}
                            </a>
                        </li>
                    ))}
                </ul>
                
                <div className='flex gap-[30px]'>
                    <div className="hidden lg:flex lg:items-center lg:justify-center lg:gap-2 w-16 h-8 p-1 bg-light dark:bg-dark rounded-[19px] cursor-pointer" onClick={toggleTheme}>
                        <span className='flex justify-center items-center dark:bg-white w-7 h-7 rounded-full'>
                            <img
                                id='darkSvg'
                                src={darkSvg}
                                alt="dark"
                                width={16}
                                height={16}
                            />
                        </span>
                        <span className={`flex justify-center items-center ${theme === 'light' && 'bg-white w-7 h-7 rounded-full'}`}>
                            <img
                                id='lightSvg'
                                src={lightSvg}
                                alt="light"
                                width={20}
                                height={theme === 'light' ? 20 : 19}
                            />    
                        </span>
                    </div>

                    <div className='hidden lg:flex lg:items-center lg:gap-6'>
                        <a href='https://srt.bitcoin.com/games-app-login' target='_blank' rel='noreferrer' className='text-custom-1 dark:text-white hover:text-custom-3 text-base font-bold leading-[17.6px] cursor-pointer'>Login</a>
                        <a href='https://srt.bitcoin.com/games-app-signup' target='_blank' rel='noreferrer' className='text-white bg-primary hover:bg-custom-9 text-base font-bold leading-[17.6px] cursor-pointer py-3 px-8 rounded-xl'>Sign Up</a>
                    </div>
                </div>

                <div onClick={handleNavbar} className='block lg:hidden'>
                    {isOpen ? <AiOutlineClose color={theme === 'light' ? '#000000' : '#FFFFFF'} size={20} /> : <AiOutlineMenu color={theme === 'light' ? '#000000' : '#FFFFFF'} size={20} />}
                </div>

                { isOpen && (
                    <div className='z-10 absolute top-[48px] left-0 right-0 w-full h-[calc(100vh-48px)] bg-custom-light dark:bg-custom-dark transition'>
                        <div className='flex flex-col justify-center h-[calc(100%-130px)] overflow-y-auto'>
                            <ul className='lg:hidden flex flex-col items-center justify-center m-0 w-full p-0'>
                                {navItems.map(item => (
                                    <li key={item.id} className='p-4'>
                                        <a
                                            href={item.link}
                                            {...(item.externalLink ? { target: '_blank', rel: 'noreferrer' } : {})}
                                            className='text-custom-1 dark:text-white hover:text-custom-3 text-2xl font-bold leading-8 cursor-pointer'
                                        >
                                            {item.text}
                                        </a>
                                    </li>
                                ))}
                                <li className='p-4'>
                                    <div className="flex items-center justify-center gap-2 w-16 h-8 p-1 bg-light dark:bg-dark rounded-[19px] cursor-pointer" onClick={toggleTheme}>
                                        <span className='flex justify-center items-center dark:bg-white w-7 h-7 rounded-full'>
                                            <img
                                                id='darkSvg'
                                                src={darkSvg}
                                                alt="dark"
                                                width={16}
                                                height={16}
                                            />
                                        </span>
                                        <span className={`flex justify-center items-center ${theme === 'light' && 'bg-white w-7 h-7 rounded-full'}`}>
                                            <img
                                                id='lightSvg'
                                                src={lightSvg}
                                                alt="light"
                                                width={20}
                                                height={theme === 'light' ? 20 : 19}
                                            />    
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='lg:hidden flex flex-col items-center mx-6 gap-2'>
                            <a href='https://srt.bitcoin.com/games-app-login' target='_blank' rel='noreferrer' className='text-custom-1 dark:text-white hover:text-custom-3 text-2xl font-bold leading-8 cursor-pointer'>Login</a>
                            <a href='https://srt.bitcoin.com/games-app-signup' target='_blank' rel='noreferrer' className='w-full text-white bg-primary hover:bg-custom-9 text-xl font-bold leading-[22px] text-center cursor-pointer py-[17px] rounded-xl'>Sign Up</a>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Header;
