import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useDispatch } from 'react-redux';
import { fetchCountryCode } from '../../store/countryCodeSlice';

const Layouts = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCountryCode());
    }, [dispatch]);

    return (
        <div className='bg-custom-light dark:bg-custom-dark lg:px-12 px-4 lg:pb-12 pb-4'>
            <Header />
            <main className='w-100'>
                <div>
                    <Outlet/>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Layouts
