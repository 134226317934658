import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchGames = createAsyncThunk('games/fetchGames', async () => {
    const response = await axios.get('https://api.prod05.beti.io/game_info_view/public/games?size=1000&countryCode=IN&brandId=bccashgames&currencyCode=BTC&status=ENABLED', {
        headers: {'Accept': 'application/json'}
    });
    const data = await response.data.content;
    return data;
});

const gamesSlice = createSlice({
    name: 'games',
    initialState: {
        games: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGames.fulfilled, (state, action) => {
            state.games = action.payload;
        });
    },
});

export const selectGamesByCategory = (state, categoryId) => {
    const filteredGames = state.games.games.filter(game => 
        game.tags && game.tags.some(tag => 
            tag.key === "category" && tag.value.includes(categoryId.toString()),
        )
    );
    return filteredGames.slice(0, 10);
};

export const selectRandomGames = (state) => {
    const allGames = state.games.games;
    let randomGames = [];
    let usedIndices = new Set();

    while(randomGames.length < 10 && usedIndices.size < allGames.length) {
        const randomIndex = Math.floor(Math.random() * allGames.length);
        if (!usedIndices.has(randomIndex)) {
            randomGames.push(allGames[randomIndex]);
            usedIndices.add(randomIndex);
        }
    }
    
    return randomGames;
};

export default gamesSlice.reducer;
